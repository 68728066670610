import "react";


import React, { useEffect, useState } from "react";
import axios from "axios";
import useAuthentication from "../../hooks/useAuthentication";
import SyncConstants from "../../constants/SyncConstants";
import MinutesToTime from "./MinutesToTime";
import stylelist from "stylelist";

import SharkIf from "../Util/IfCondition";

import { generateRandomTiming } from "../../libs/StringUtil";

const MonthlyBreakdown = (props) => {

    const Auth = useAuthentication();

    const load = () => {
        axios({
            method: "get",
            auth: Auth.getAuthObject(),
            url: SyncConstants["syncBaseURL"] + "test",
            params: {
                parent: props.project_id
            },
            responseType: 'json'
        }).then((response) => {

            setData(response.data);

        }).catch(response => { });

    };

    useEffect(() => {
        load();
    }, []);

    const [data, setData] = useState({
        projects: [
            // JSON data here as given
        ],
        monthly_summary: {
            "2024-09": 13325,
            "2024-10": 9860,
            "2024-11": 7665,
            "2024-12": 5160,
        },
    });

    const allMonths = [
        ...new Set(
            data.projects.flatMap((project) => Object.keys(project.monthly_breakdown))
        ),
    ].sort();

    return (
        <>
            <h5 className="mt-3 mb-0">Breakdown</h5>

            <h6 class="text-muted mb-4 mt-3">Ansicht aller Unterprojekte (rekursiv), inkl. Summe an Aufwänden.</h6>

          

            <table className="table table-bordered mb-0 small table-hover table-striped">
                <thead>
                    <tr>
                        <th className="align-middle" scope="col">Projektbezeichnung</th>
                        <th className="text-center align-middle" scope="col">Gesamtaufwand</th>
                        {allMonths.map((month) => (
                            <th className="text-center align-middle" scope="col" key={month}>{month}</th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {data.projects.map((project, index) => (
                        <tr key={index}>
                            <td>

                                <SharkIf animate={true} show={true} animation="fade" animationDelay={generateRandomTiming()}>
                                    <>
                                        {(project.breadcrumb != project.project_title && project.breadcrumb != "")
                                            &&
                                            <>
                                                <span className="extra-small-tabletext text-muted  d-block mb-1">{project.breadcrumb}</span>
                                            </>

                                        }

                                        {project.project_title}
                                        {(project.maxminutesmonth > 0 || project.maxminutes > 0) &&
                                            <>
                                                <br />
                                                <small className="text-muted extra-small-tabletext">(
                                                    {project.maxminutesmonth > 0 &&
                                                        <>
                                                            <MinutesToTime minutes={project.maxminutesmonth} /> p. M.
                                                            {project.maxminutes > 0 && ", "}
                                                        </>
                                                    }

                                                    {project.maxminutes > 0 &&
                                                        <>
                                                            Gesamt <MinutesToTime minutes={project.maxminutes} />
                                                        </>
                                                    }

                                                    )

                                                </small>
                                            </>
                                        }
                                    </>
                                </SharkIf>
                            </td>


                            <td className="text-center align-middle">
                                <SharkIf animate={true} show={true} animation="fade" animationDelay={generateRandomTiming()}>
                                    <MinutesToTime minutes={project.total_minutes} />
                                    {(project.maxminutes > 0 && project.total_minutes != 0) &&
                                        <>
                                            <br /> <small className={stylelist("extra-small-tabletext",
                                                [(project.maxminutes - project.total_minutes) < 0, "text-danger"],
                                                [(project.maxminutes - project.total_minutes) > 1, "text-success"])}>
                                                (<MinutesToTime minutes={project.maxminutes - project.total_minutes} />)
                                            </small>
                                        </>
                                    }
                                </SharkIf>
                            </td>


                            {allMonths.map((month) => (

                                <td key={month} className={stylelist("text-center", "align-middle")}>
                                    <span className={stylelist([(project.monthly_breakdown[month] || 0) < 1, "opacity-25"],)}>

                                        <SharkIf animate={true} show={true} animation="fade" animationDelay={generateRandomTiming()}>
                                            <MinutesToTime minutes={project.monthly_breakdown[month] || 0} />
                                            {project.maxminutesmonth > 0 &&
                                                <>
                                                    <br /> <small className={stylelist("extra-small-tabletext",
                                                        [(project.maxminutesmonth - project.monthly_breakdown[month] || 0) < 0, "text-danger"],
                                                        [(project.maxminutesmonth - project.monthly_breakdown[month] || 0) > 1, "text-success"])}>
                                                        (<MinutesToTime minutes={project.maxminutesmonth - project.monthly_breakdown[month] || 0} />)
                                                    </small>
                                                </>
                                            }
                                        </SharkIf>

                                    </span>
                                </td>

                            ))}
                        </tr>
                    ))}
                    <tr>
                        <td className=" align-middle"><strong>Monatliche Zusammenfassung</strong></td>
                        <td className="text-center align-middle">
                            <strong>
                                <MinutesToTime minutes={Object.values(data.monthly_summary).reduce((a, b) => a + b, 0)} />


                            </strong>


                        </td>
                        {allMonths.map((month) => (
                            <td key={month} className="text-center align-middle">
                                <strong>
                                    <MinutesToTime minutes={data.monthly_summary[month] || 0} />
                                </strong>

                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </>
    );
};

export default MonthlyBreakdown;