import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'moment/locale/de';
import stylelist from "stylelist";
import { useHistory, useParams, Link } from "react-router-dom";
import { add_new_slot, delete_slot, update_slot } from "../../actions/Slots";
import { copy, getSlotDate, getSlotMetaFromId } from "../../libs/SlotUtils";
import UserSelector from "./UserSelector";
import axios from "axios";
import Expenses from "../Expenses/ExpenseProject";
import SyncConstants from "../../constants/SyncConstants";
import { set_notification } from "../../actions/Sync";
import { Activities } from "../../constants/ExpenseConstants";
import useAuthentication from "../../hooks/useAuthentication";


const ExpenseMask = (props) => {

    const [selectedProject, setSelectedProject] = useState(null);
    const [newSelectedProject, setNewSelectedProject] = useState(null);
    const [showNewProjectSelector, setShowNewProjectSelector] = useState(false);
    const UserSettings = useSelector((state) => state.User);
    const Team  = useSelector((state) => state.Team);

    const [updateId, setUpdateId] = useState(-1);
    const [title, setTitle] = useState("");
    const [durationHours, setDurationHours] = useState("0");
    const [durationMinutes, setDurationMinutes] = useState("0");
    const [expenseDate, setExpenseDate] = useState (getSlotDate(new Date));
    const [activity, setActivity] = useState(Team[UserSettings.userName].defaultActivity);
    const [isWaiting, setIsWaiting] = useState(false);
    const Auth = useAuthentication();

    const [updateOrNew, setUpdateOrNew] = useState("new");

    const dispatch = useDispatch();
    
    useEffect(() => {
        if(props.slotId) {
            setExpenseDate(getSlotMetaFromId(props.slotId).slotDate);
        }else {
            setExpenseDate(getSlotDate(new Date()));
        }

        if (props.forProject != undefined) {
                setSelectedProject(props.forProject)
        }

        if(props.update == true){
            setUpdateOrNew("update");
            setUpdateId(props.updateEntry.id);
            setTitle(props.updateEntry.comment);
            setActivity(props.updateEntry.activity);
            setSelectedProject({id : props.updateEntry.expense_project_id});
            setDurationHours(Math.floor(props.updateEntry.minutes / 60).toString().padStart(2,"0"));
            setDurationMinutes((props.updateEntry.minutes % 60).toString().padStart(2,"0"));
            setExpenseDate(props.updateEntry.date);
        }
    }, []);

    const sendToServer = () => {
        setIsWaiting(true);
        axios({
            method: "post",
            auth:Auth.getAuthObject(),
            url: SyncConstants["syncBaseURL"] + "expenseEntry",
            responseType: 'json',
            data: {
                title : title,
                updateId : updateId,
                durationHours : durationHours,
                durationMinutes : durationMinutes,
                expenseProjectId : newSelectedProject ? newSelectedProject.id : selectedProject.id,
                person : props.update ? props.updateEntry.person : UserSettings.userName,
                slotId : props.slotId == undefined ? props.updateEntry.slot_id : props.slotId,
                expenseDate : expenseDate,
                entryDate : getSlotDate(new Date()),
                activity : activity
            }
        }).then((response) => {
            setTitle("");
            setDurationHours(0);
            setDurationMinutes(0);
            setExpenseDate(getSlotDate(new Date()));
            setSelectedProject(null);
            dispatch(set_notification("Erfolgreich gebucht", "success"));
            props.afterSubmit(newSelectedProject ? true : false);
            setIsWaiting(false);
        }).catch(response => {
            setIsWaiting(false);
        });
    }

    return <>
        <form>
            <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <h6>Tätigkeit</h6>
                            <input type="text" className="form-control" value={title} onChange={e => {setTitle(e.target.value)}} />
                            <span className="small text-muted ms-1">Beschreibung der durchgeführten Tätigkeit.</span>
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="form-group">
                            <h6>Datum</h6>
                            <input type="date" className="form-control" value={expenseDate} onChange={e => {setExpenseDate(e.target.value)}} />
                            <span className="small text-muted ms-1">Datum der Buchung.</span>
                        </div>
                    </div>
                </div>

            <div className="row">
                <div className="col-6">
                    <div className="form-group mt-4">
                        <h6>Dauer</h6>
                    
                        <div className="row">
                            <div className="col-5">
                                <input type="text" placeholder="Stunden" className="form-control" value={durationHours} onChange={e => {setDurationHours(e.target.value)}} />
                                <span className="small text-muted ms-1">Stunden</span>
                            </div>
                            <div className="col-5">
                                <input type="text" placeholder="Minuten" className="form-control" value={durationMinutes} onChange={e => {setDurationMinutes(e.target.value)}}/>
                                <span className="small text-muted ms-1">Minuten</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-6">
                    <div className="form-group mt-4">
                        <h6>Tätigkeit</h6>
                            <select value={activity} className="form-select" onChange={e => { setActivity(e.target.value) }}>
                                {Object.keys(Activities).map(key => {
                                    return <>
                                        <option value={key}>{Activities[key]}</option>
                                    </>
                                })}

                            </select>
                    </div>
                </div>
            </div>

            <div className="form-group mt-4">
            <h6>Projekt</h6>

                {updateOrNew == "new" && 
                    <>
                       
                        {Boolean(selectedProject !== null ) &&
                            <div className="small">
                                {selectedProject.title} &nbsp; 
                                
                                {!props.hideDeleteButton &&
                                    <>
                                        (<span className="text-danger cursor-pointer" onClick={() => {setSelectedProject(null)}}>Löschen</span>)
                                
                                    </>
                                }
                                
                            </div>
                        }

                        {selectedProject == null &&
                            <>
                                <Expenses
                                    onProjectSelect={p => { debug(p) }}
                                    expenseProjectId="1"
                                    level="1"
                                    filterDateFrom={false}
                                    filterDateTo={false}
                                    filterPerson={false}
                                    selectMode={true}
                                    onSelect={(e) => { setSelectedProject(e) }}
                                />
                            </>
                        }
                        </>
                }

                {updateOrNew == "update" &&
                    <>
                        {(showNewProjectSelector && newSelectedProject == null) && 
                            <>
                                <Expenses
                                        onProjectSelect={p => { debug(p) }}
                                        expenseProjectId="1"
                                        level="1"
                                        filterDateFrom={false}
                                        filterDateTo={false}
                                        filterPerson={false}
                                        selectMode={true}
                                        onSelect={(e) => { setNewSelectedProject(e) ; console.log(e); ; setShowNewProjectSelector(false); }}
                                    />
                            </>
                        }    

                        {!showNewProjectSelector && newSelectedProject != null &&
                            <>
                                <p>Die Projekt-ID wird beim Speichern auf <b>{newSelectedProject.title}</b> geändert.</p>
                            </>
                        }        

                        {!showNewProjectSelector && newSelectedProject == null &&
                            <>
                              <div className="small text-primary cursor-pointer" onClick={() => {setShowNewProjectSelector(true)}}>Projekt ändern</div>
                            </>
                        }        
                    </>
                
                }
            </div>

            <div className="form-group mt-3">
                {(title != "" && selectedProject != null && durationHours != "" && durationMinutes != "") && 
                    <>
                        {isWaiting && 
                            <>
                                <div className="btn btn-secondary btn-sm" onClick={() => {alert("Es wird aktuell auf eine Antwort vom Server gewartet.")}}>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                </div> 
                            </>
                        }
                
                        {!isWaiting && 
                            <>
                                <div className="btn btn-secondary btn-sm" onClick={() => {sendToServer();}}>Senden</div>
                            </>
                        }
                    </>
                }

                {!(title != "" && selectedProject != null && durationHours != "" && durationMinutes != "") && 
                    <>
                        <div className="btn btn-sm btn-secondary small muted cursor-na">Alle Felder zum Absenden ausfüllen.</div>
                    </>
                }
            </div>
        </form>
    </>

}

export default ExpenseMask;