import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import { set_sync_status } from "../actions/Sync.js";
import SlotCalendar from "../components/Slots/SlotCalendar";
import Welcome from "../components/Welcome/Welcome.jsx";
import SyncConstants from "../constants/SyncConstants.jsx";
import Dashboard from "./Dashboard/Dashboard.jsx";
import ExpensesPage from "./Expenses/ExpensesPage.jsx";
import Menu from "./Menu/Menu.jsx";
import NG from "./NG/NG.jsx";
import Settings from "./Settings/Settings.jsx";
import AddSlot from "./Slots/AddSlot.jsx";
import TeamManagement from "./Team/TeamManagement.jsx";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";
import useAuthentication from "../hooks/useAuthentication.jsx";

const Slotify = (props) => {

  // -- GENERAL HOOKS Start --
  const dispatch = useDispatch();
  const Sync = useSelector((state) => state.Sync);
  const Team = useSelector((state) => state.Team);
  const UserSettings = useSelector((state) => state.User);
  const location = useLocation();
  const Auth = useAuthentication();
  // -- GENERAL HOOKS End -- 


  // -- STATE HOOKS Start --
  // updateScreen (Boolean): Indicates if the modal for a new slotify-update is shown
  const [updateScreen, setUpdateScreen] = useState(false);

  // dismissUpdate (Boolean): Indicates if the user has chosen to skip the update check for the current session
  const [dismissUpdate, setDismissUpdate] = useState(false);
  
  const [updateMessage, setUpdateMessage] = useState("Es wird immer besser!");
  // -- STATE HOOKS End --



  // -- EFFECTS Start --
  // checkForUserLogin: Check, if a username is set - if so, perform a sync
  useEffect(() => {
    if (typeof Team[UserSettings["userName"]] != "undefined") {
      dispatch(set_sync_status("asap"));
    }
  }, []);


  // updateCheck: Check, a newer version of slotify is available - if so, show the update screen
  useEffect(() => {
    let timer = setInterval(() => {
      axios({
        method: "get",
        auth: Auth.getAuthObject(),
        url: SyncConstants["syncBaseURL"] + "updateLevel",
        responseType: 'json'
      }).then((response) => {
        if (response.data.update_level > SyncConstants["slotifyVersion"]) {
          setUpdateScreen(true);
          setUpdateMessage(response.data.update_message);
        }
      }).catch(response => { });
    }, 60000);

    return () => {
      clearInterval(timer);
    }
  }, []);
  // -- EFFECTS End --

  // -- RENDERING Start --
  return (
    <>
      <Menu />

      {Sync.loginStatus === "offline" &&
        <Welcome />
      }

      {(updateScreen && !dismissUpdate) &&
        <>
          <div className="overlay d-flex justify-content-center align-items-center">
            <div className="modal d-block">
              <div className="modal-dialog" style={{ minWidth: "1024px", marginTop: "200px" }}>
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Slotify-Update verfügbar</h5>
                  </div>
                  <div className="modal-body">
                    <p>Auf dem Server ist ein Slotify-Update verfügbar. Lade diese Seite neu, um von neuen Funktionen und Fehlerverbesserungen zu profitieren.</p>
                
                    <p className="small text-muted"><b>Update-Info: </b>{updateMessage}</p>
                  </div>
                  <div className="modal-footer">
                    <a className="cursor-pointer text-primary" onClick={() => { setDismissUpdate(true); }}>Für diese Sitzung ignorieren</a>
                    <a className="cursor-pointer btn btn-primary ms-2" onClick={() => { window.location.reload(); }}>Update anwenden</a>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }

      {Sync.loginStatus === "loggedIn" &&
        <div className="">

          <Switch location={location}>

            <Route exact path="/">
              <Dashboard />
            </Route>

            <Route exact path="/dashboard">
              <Dashboard />
            </Route>

            <Route exact path="/team">
              <TeamManagement />
            </Route>

            <Route exact path="/calendar">
              {typeof Team[UserSettings["userName"]] != "undefined" &&
                <>
                  {Team[UserSettings["userName"]].hasCalendar && <>
                    <SlotCalendar />
                  </>}

                  {!Team[UserSettings["userName"]].hasCalendar && <>
                    <div className="container mt-5">
                      <h1>Slotkalender</h1>
                      <p>Dein Account ist aktuell mit keinem Team verknüpft, welches über den Slotkalender verfügt.<br />Nutze das Modul <Link to="/expenses">Aufwände</Link>, um deine Arbeitsaufwände festzuhalten.</p>
                    </div>
                  </>}
                </>
              }

              {typeof Team[UserSettings["userName"]] == "undefined" &&
                <>
                  <SlotCalendar />
                </>
              }
            </Route>

            <Route exact path="/expenses">
              <ExpensesPage />
            </Route>

            <Route exact path="/expenses/:id">
              <ExpensesPage />
            </Route>

            <Route exact path="/ng">
              <NG />
            </Route>

            <Route exact path="/settings">
              <Settings />
            </Route>

            <Route exact path="/slots/add">
              <AddSlot />
            </Route>

            <Route exact path="/slots/edit/:slotId">
              <div className="container mt-5">
                <AddSlot />
              </div>
            </Route>

            <Route path="/about"></Route>

          </Switch>

        </div>
      }

    </>
  );
  // -- RENDERING Start --
};

export default Slotify;