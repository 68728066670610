export const stripTags = (string) => {
    return string.replace(/(<([^>]+)>)/gi, "");
}

export const htmlEntitiesDecode = (string) => {
    
}

export const generateRandomClassName = (length = 8) =>
    'cls-' + Array.from({ length }, () => Math.random().toString(36)[2]).join('');


export const generateRandomTiming = () => { return Math.floor(Math.random() * 200) + 1 }
