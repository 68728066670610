import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import SyncConstants from "../../constants/SyncConstants";
import useAuthentication from "../../hooks/useAuthentication";

const NewProject = (props) => {
    const Auth = useAuthentication();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [comment, setComment] = useState("");
    const [bookable, setBookable] = useState(false);
    const [durationHours, setDurationHours] = useState("0");
    const [durationMinutes, setDurationMinutes] = useState("0");
    const [durationMonthMinutes, setDurationMonthMinutes] = useState("0");
    const [durationMonthHours, setDurationMonthHours] = useState("0");

    const [projectStatus, setProjectStatus] = useState(1);

    const [id, setId] = useState(false);

    const [needDeepRefresh, setNeedDeepRefresh] = useState(false);

    const sendToServer = () => {
        axios({
            method: "post",
            auth: Auth.getAuthObject(),
            url: SyncConstants["syncBaseURL"] + "expenseProject",
            responseType: 'json',
            data: {
                title: title,
                id: id,
                description: description,
                comment: comment,
                bookable: bookable,
                status : projectStatus,
                parentId: props.projectParentId,
                maxminutes: parseInt(durationHours * 60) + parseInt(durationMinutes),
                maxminutesmonth: parseInt(durationMonthHours * 60) + parseInt(durationMonthMinutes)
            }
        }).then(() => {
            props.afterSubmit(needDeepRefresh);

        }).catch(() => { });
    }

    const openIdFromServer = (id) => {
        axios({
            method: "get",
            auth: Auth.getAuthObject(),
            url: SyncConstants["syncBaseURL"] + "expenseProject",
            responseType: 'json',
            params: {
                id: id
            }
        }).then((response) => {
            setTitle(response.data.title);
            setDescription(response.data.description);
            setComment(response.data.notes);
            setProjectStatus(response.data.status);
            setBookable(response.data.bookable > 0 ? true : false);
            setId(response.data.id);
            if (response.data.maxminutes > 0) {
                setDurationHours(Math.floor(response.data.maxminutes / 60));
                setDurationMinutes(response.data.maxminutes - (Math.floor(response.data.maxminutes / 60) * 60));
            }

            if (response.data.maxminutesmonth > 0) {
                setDurationMonthHours(Math.floor(response.data.maxminutesmonth / 60));
                setDurationMonthMinutes(response.data.maxminutesmonth - (Math.floor(response.data.maxminutesmonth / 60) * 60));
            }

        }).catch(() => { });
    }

    useEffect(() => {
        if (props.editId > 0) {
            openIdFromServer(props.editId);
        } else {
            console.log("No edit id");
        }
    }, []);

    return <>
        <div>
            <form className="bg-light py-4 px-4 border">

                <h5 className="mb-3">Projekt anlegen oder bearbeiten</h5>

                <div className="row justify-content-between">
                    <div className="col-6">
                        <div className="form-group mb-4">
                            <h6>Titel</h6>
                            <input type="text" className="form-control mb-2" value={title} onChange={e => { setTitle(e.target.value) }} />

                        </div>

                        <div className="form-group mb-4">
                            <h6>Beschreibung</h6>
                            <textarea className="form-control" rows="3" value={description} onChange={e => { setDescription(e.target.value) }} />
                        </div>

                        <div className="form-group mb-4">
                            <h6>Kommentar</h6>
                            <textarea className="form-control" rows="5" value={comment} onChange={e => { setComment(e.target.value) }} />
                        </div>
                    </div>

                    <div className="col-4">
                        <h6 className="">Maximale Stunden (für den Auftrag, optional)</h6>
                        <div className="row mb-4">
                            <div className="col-6">
                                <div className="input-group">
                                    <input type="text" placeholder="Stunden" className="form-control" value={durationHours} onChange={e => { setDurationHours(e.target.value) }} />
                                    <span class="input-group-text">Stunden</span>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="input-group">
                                    <input type="text" placeholder="Minuten" className="form-control" value={durationMinutes} onChange={e => { setDurationMinutes(e.target.value) }} />
                                    <span class="input-group-text">Minuten</span>

                                </div>
                            </div>
                        </div>

                        <h6 className="">Maximale Stunden (pro Monat, optional)</h6>
                        <div className="row mb-4 ">
                            <div className="col-6">
                                <div className="input-group">
                                    <input type="text" placeholder="Stunden" className="form-control" value={durationMonthHours} onChange={e => { setDurationMonthHours(e.target.value) }} />
                                    <span class="input-group-text">Stunden</span>
                                </div>

                            </div>
                            <div className="col-6">
                                <div className="input-group">
                                    <input type="text" placeholder="Minuten" className="form-control" value={durationMonthMinutes} onChange={e => { setDurationMonthMinutes(e.target.value) }} />
                                    <span class="input-group-text">Minuten</span>
                                </div>
                            </div>
                        </div>

                        <h6>Bookable</h6>
                        <div className="row mb-4">
                            <div className="col-12">
                                <div className="form-check form-switch  mb-2">
                                    <input className="form-check-input mb-2" type="checkbox" checked={bookable} onChange={e => { setBookable(e.target.checked) }} />
                                    <label className="form-check-label" for="flexSwitchCheckDefault">Kann Buchungen erhalten?</label>
                                </div>
                            </div>
                        </div>

                        <h6>Status</h6>

                        <div className="row mb-4 ms-2">
                            <div className="form-check">
                                <input className="form-check-input" 
                                        type="radio" 
                                        name="flexRadioDefault" 
                                        id="flexRadioDefault1" 
                                        value="1"
                                        checked={projectStatus == "1"}
                                        onChange={e => {setProjectStatus(e.target.value); setNeedDeepRefresh(true);}}
                                        
                                        
                                        />
                                <label className="form-check-label" for="flexRadioDefault1">
                                    Aktiv
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" 
                                        type="radio" 
                                        name="flexRadioDefault" 
                                        id="flexRadioDefault2" 
                                        value="2"
                                        checked={projectStatus == "2"}
                                        onChange={e => {setProjectStatus(e.target.value); setNeedDeepRefresh(true);}}
                                        
                                        />
                                <label className="form-check-label" for="flexRadioDefault2">
                                    Archiviert
                                </label>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row">
                <div className="col-12">
                        
                        {projectStatus === "2" &&
                                    <>
                                        <p className="alert alert-danger">Archivierte Projekte verschwinden aktuell komplett aus Slotify und können nur von Admins über die Datenbank wieder aktiviert werden.
                                        
                                        </p>

                                    </>
                                }
                        </div>
                </div>

                <div className="row mt-2">

                    

                    <div className="col-3">
                        {(title != "" && props.editId === undefined) &&
                            <button type="button" className="btn btn-primary" onClick={() => { sendToServer() }}>Erstellen</button>
                        }

                        {(title != "" && props.editId !== undefined) &&
                            <button type="button" className="btn btn-primary" onClick={() => { sendToServer() }}>Speichern</button>
                        }
                    </div>
                </div>
            </form>

        </div>
    </>
}

export default NewProject